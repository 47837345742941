//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : FacebookLoginButton.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240705     Jecyline     create FacebookLoginButton.jsx
//* 20240717     Jecyline     get REACT_APP_FACEBOOK_APP_ID from env
//* 20240805     Jecyline     set feedbackModalShown to localStorage
//
//***************************************************************************/
import React,  { useEffect, useState }  from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props' ;
import { api_url } from "../base_urls";
import { facebookicon } from "../Routes/ImagePath";

const FacebookLoginButton = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState('');

  const responseFacebook = async (response) => {
      if (response.accessToken) {
        // User is logged in
        console.log('Successfully logged in', response);
  
        try {  
          // Send the token to your backend for further validation or login/signup
          const responseData = await axios.post(api_url + '/auth/facebook', {
            accessToken: response.accessToken
          });
          const { access_token, token_type, expires_in , user} = responseData.data;

          if (user.status === 2) { // first time login user
            localStorage.setItem('access_token', access_token); // Save access token
            localStorage.setItem('token_type', token_type); // Save the token type
            localStorage.setItem('expires_in', expires_in); // Save token expiration time
            localStorage.setItem('feedbackModalShown', 'false');
            navigate('/first-time-login');
          } else if (user.status === 3) { // active user
            localStorage.setItem('access_token', access_token); // Save access token
            localStorage.setItem('token_type', token_type); // Save the token type
            localStorage.setItem('expires_in', expires_in); // Save token expiration time
            localStorage.setItem('feedbackModalShown', 'false');
            navigate('/dashboard');
          } else if (user.status === 1) { // pending user
            const redirect = 'first-time-login';
            navigate('/otp', { state: { email: user.email , redirect: redirect } });
            console.log(user);
            const email = user.email;
            try {
              const response = await fetch(api_url + '/resend-otp', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email , redirect})
              });
      
              if (!response.ok) {
                  const errorData = await response.json();
                  throw new Error(errorData.error || 'Failed to resend otp');
              } else {
                const responseData = await response.json();
                // console.log(responseData);
      
                setMessage(responseData.message);
                setTimeout(() => setMessage(''), 10000); // Clear message after 5 seconds
              }
      
            } catch (error) {
              console.error('Error:', error.message);
            }
          }
        } catch (error) {
          console.error('Error during backend request:', error);
          // alert('Error during Facebook login. Please try again.');
        }
      
      } else {
        // User is not logged in
        console.log('Login failed', response);
        // alert('Facebook login failed. Please try again.');
      }
    };
  
    return (
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email"
        callback={responseFacebook}
        render={renderProps => (
          <button 
            onClick={renderProps.onClick} 
            className="facebook-button" 

          >
            <i className="fa-brands fa-facebook" ></i>
            <div className='social-text'>Sign in with Facebook</div>
          </button>
        )}
        // cssClass="my-facebook-button"
        // icon="fa-facebook"
      />
    );
  };
  

export default FacebookLoginButton;


