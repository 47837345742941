//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatSlice.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (24 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240724     Jecyline      create chatSlice.jsx for chat reducer
//* 20240726     Jecyline      add editCurrentChatData, amend appendCurrentChatDetails and editCurrentChatDetails to update by date
//* 20240729     Jecyline      add setChatLoader, amend setCurrentChatData to add chat loader, amend appendCurrentChatDetails and editCurrentChatDetails to check for chat id before append
//* 20240801     Jecyline      add prependCurrentChatDetails, setCurrentPage, setHasMore, setIsLoadingChatDetails, setIsLoadingFirstPage for chat details pagination
//* 20240805     Jecyline      add setLastChatDetailId for chat details reaction
//* 20240807     Jecyline      add setIsSufficientCredit
//* 20240813     Jecyline      add setChatSelection, editChatSelection
//* 20240830     Jecyline      amend setCurrentChatDetails: to store chat details
//* 20240905     Jecyline      set initial currentPage to 0, amend setChatSelection(), add chatStage, setChatStage(), editChatStage()
//* 20240911     Jecyline      amend setChatStage for new brain flow
//* 20240930     Jecyline      add chatOption, setChatOption
//* 20240930     Jecyline      add source_url, source_desc, mask_url, mask_desc to setChatStage 
//* 20240930     Jecyline      add tempPrompt, setTempPrompt to fix chat loading issue
//* 20241002     Jecyline      add setLoadingMoreAds, amend setCurrentChatDetails - add end cursor
//
//***************************************************************************/
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  chatList: [],
  todayChats: [],
  yesterdayChats: [],
  last7DaysChats: [],
  olderDaysChats: [],
  currentChatData: null,
  currentChatDetails: [],
  chatLoader: {},
  loadingMoreAds: {},
  tempPrompt: {},
  currentPage: 0,
  hasMore: true,
  brandBoostDetail: {},
  isLoadingChatDetails: true,
  isLoadingFirstPage: false,
  lastChatDetailId: '',
  isSufficientCredit: true,
  chatSelection: {},
  chatMemory: [],
  bottomRefTrigger: null,
  chatStage: {},
  chatOption: {},
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatList: (state, action) => {
      state.chatList = action.payload.chatList;
      state.todayChats = action.payload.today;
      state.yesterdayChats = action.payload.yesterday;
      state.last7DaysChats = action.payload.last7Days;
      state.olderDaysChats = action.payload.olderDays;
    },
    setCurrentChatData: (state, action) => {
      state.currentChatData = action.payload;

      //set chat loader if not added before
      if (action.payload && !state.chatLoader[state.currentChatData.id]) {
        state.chatLoader[state.currentChatData.id] = { isLoading: false, message: "" };
      }
    },
    editCurrentChatData: (state, action) => {
      const { field, value } = action.payload;
      if (state.currentChatData.hasOwnProperty(field)) {
        state.currentChatData[field] = value;
      }
    },
    setCurrentChatDetails: (state, action) => {
      const { date, newDetails } = action.payload;
      if (date === '') {
        state.currentChatDetails = [];
      } else {
        state.currentChatDetails[date] = newDetails;
      }
    },
    appendCurrentChatDetails: (state, action) => {
      // Ensure action.payload is an array
      const newDetails = action.payload;//Array.isArray(action.payload) ? action.payload : [action.payload];

      if (Number(newDetails[0].chat_id) !== Number(state.currentChatData.id)) {
        return;
      }
      
      //get today date
      const today = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
      
      // Temporary storage for new chat details to be merged into state
      let updatedChatDetails = { ...state.currentChatDetails };

      // Check if today's date exists in currentChatDetails
      if (!updatedChatDetails[today]) {
        // If not, initialize it with the new messages
        updatedChatDetails = {
          ...updatedChatDetails,
          [today]: newDetails,
        };
      } else {
        // If it exists, append the new messages to the existing ones
        updatedChatDetails[today] = [
          ...updatedChatDetails[today],
          ...newDetails,
        ];
      }

      state.currentChatDetails = updatedChatDetails;
    }, 
    setBrandBoostDetail: (state, action) => {
      const { chat_id } = action.payload;
      console.log('setBrandBoostDetail chat_id', chat_id);

      if (!state.brandBoostDetail[chat_id]) {
        state.brandBoostDetail[chat_id] = { 
          keyword_approach: '',
          keyword: '',
          keyword_url: '',
          facebook_keyword: '',
          flexible_spec_interest: {},
          reach: '',
          target_audience: {},
          target_spec: {},
          budget: '',
          // audience_size_lower_bound:'',
          // audience_size_upper_bound:'',
          summary:'',
        };
      }
      console.log('setBrandBoostDetail state.brandBoostDetail[chat_id]', state.brandBoostDetail[chat_id]);

    },
    editBrandBoostDetail: (state, action) => {
      const { chat_id, updates } = action.payload;
      
      console.log('editBrandBoostDetail updates', updates);
      console.log('editBrandBoostDetail before state.brandBoostDetail[chat_id]', state.brandBoostDetail[chat_id]);

      if (state.brandBoostDetail[chat_id]) {
        state.brandBoostDetail[chat_id] = {
          ...state.brandBoostDetail[chat_id],
          ...updates
        };
      }

      console.log('editBrandBoostDetail after state.brandBoostDetail[chat_id]', state.brandBoostDetail[chat_id]);
    },
    prependCurrentChatDetails: (state, action) => {
      const { chat_id, chatData } = action.payload;
      if (Number(chat_id) !== Number(state.currentChatData.id)) {
        return;
      }
      
      // Temporary storage for new chat details to be merged into state
      let updatedChatDetails = { ...state.currentChatDetails };

      Object.entries(chatData).forEach(([date, chatDetails]) => {
        // Check if date exists in updatedChatDetails
        if (!updatedChatDetails[date]) {
          // If the date does not exist, prepend a new entry with date key
          updatedChatDetails = {
            [date]: chatDetails,
            ...updatedChatDetails
          };
        } else {
          updatedChatDetails[date] = [
            ...chatDetails, // New details come first
            ...updatedChatDetails[date] // Existing details follow
          ];
        }
      });

      state.currentChatDetails = updatedChatDetails;
    },  
    editCurrentChatDetails: (state, action) => {

      const { chat_id, chat_detail_id, updatedData } = action.payload;

      if (Number(chat_id) !== Number(state.currentChatData.id)) {
        return;
      }
    
      // Get an array of all dates and sort them in reverse order (latest date first)
      const dates = Object.keys(state.currentChatDetails).sort((a, b) => new Date(b) - new Date(a));
    
      // Iterate over the dates starting from the latest
      for (const date of dates) {
        const chatDetails = state.currentChatDetails[date];

        // Check if chatDetails is an array
        if (Array.isArray(chatDetails)) {
          // Find the index of the detail with the specified id
          const index = chatDetails.findIndex(detail => detail.id === chat_detail_id);
          if (index !== -1) {
            const updatedDetails = [...chatDetails];
            updatedDetails[index] = {
              ...updatedDetails[index],
              ...updatedData
            };

            state.currentChatDetails = {
              ...state.currentChatDetails,
              [date]: updatedDetails
            };

            // Exit the loop once the update is complete
            break;
          }
        }
      }
    },
    setChatLoader: (state, action) => { //loading for waiting for chat reply
      const { chat_id, isLoading, isListening, message, temp_prompt, prompt_time } = action.payload;

      //set chat loader if not added before
      if (!state.chatLoader[chat_id]) {
        state.chatLoader[chat_id] = { isLoading: false, isListening: false, message: "", temp_prompt: "", prompt_time: "" };
      }
      state.chatLoader[chat_id] = { isLoading, isListening, message, temp_prompt, prompt_time };

    },
    setLoadingMoreAds: (state, action) => {
      const { chat_id, isLoading } = action.payload;
      state.loadingMoreAds[chat_id] = isLoading;
    },
    setChatOption: (state, action) => {
      const { chat_id, options } = action.payload;
      state.chatOption[chat_id] = options;
    },
    setTempPrompt: (state, action) => { // for temporary show prompt before storing prompt to db
      const { chat_id, temp_prompt, prompt_time } = action.payload;

      if (!state.tempPrompt[chat_id]) {
        state.tempPrompt[chat_id] = { temp_prompt: "", prompt_time: "" };
      }
      state.tempPrompt[chat_id] = { temp_prompt, prompt_time };
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setHasMore: (state, action) => {
      state.hasMore = action.payload;
    },
    setIsLoadingChatDetails: (state, action) => { //loading for load more chat details
      state.isLoadingChatDetails = action.payload;
    },
    setIsLoadingFirstPage: (state, action) => { //loading for first page of chat details
      state.isLoadingFirstPage = action.payload;
    },
    setLastChatDetailId: (state, action) => {
      state.lastChatDetailId = action.payload;
    },
    setIsSufficientCredit: (state, action) => {
      state.isSufficientCredit = action.payload;
    },
    setChatSelection: (state, action) => {
      const { 
        chat_id, 
        chat_detail_id, 
        target, 
        isDisplay, 
        title, 
        options, 
        end_cursor = '', // Default to an empty object
        animationClass 
      } = action.payload;

      if (!state.chatSelection[chat_id]) {
        state.chatSelection[chat_id] = { 
          chat_id: chat_id, 
          chat_detail_id: chat_detail_id, 
          isDisplay: false, 
          target: '',
          title: '', 
          options: [],
          end_cursor: '',
          animationClass: 'hidden',
        };
      }
      state.chatSelection[chat_id] = { chat_id, chat_detail_id, target, isDisplay, title, options, end_cursor, animationClass };
    },
    editChatSelection: (state, action) => {
      const { chat_id, updates } = action.payload;
    
      if (state.chatSelection[chat_id]) {
        state.chatSelection[chat_id] = {
          ...state.chatSelection[chat_id],
          ...updates
        };
      }
    },
    appendChatMemory: (state, action) => {
      const { chat_id, chat_memory } = action.payload;

      if (!state.chatMemory[chat_id]) {
        state.chatMemory[chat_id] = [];
      }

      if (!chat_memory || chat_memory.length === 0) {
        return; // Exit if chat_memory is empty
      } else {
        // Check if chat id exists in chatMemory
        if (!state.chatMemory[chat_id]) {
          // If the chat id does not exist, prepend a new entry with chat id key
          state.chatMemory[chat_id] = chat_memory;
        } else {
          // If it exists, append the new messages to the existing ones
          state.chatMemory[chat_id] = [
            ...state.chatMemory[chat_id],
            ...chat_memory
          ];
        }
      }
    },
    setBottomRefTrigger: (state, action) => {
      state.bottomRefTrigger = action.payload;
    },
    setChatStage: (state, action) => {
      const { chat_id } = action.payload;
      ////////////////////////////////////
      // Stages & sub Stages
      // -------------------------------
      // Stage 1 (initial)            : adsclone_location, adsclone_keyword, adsclone_method, adsclone_fb_url, adsclone_adslib,  adsclone_select_ads, adsclone_generate_selected_ads, adsclone_revise_image, adsclone_revise_copy, adsclone_revise_image_store, adsclone_revise_copy_store, adsclone_revise_image_confirm, adsclone_revise_copy_confirm, adsclone_end
      //                                brandboost_location, brandboost_keyword, brandboost_scan, brandboost_summary, brandboost_end,
      //                                copycraft_option, copycraft_image_upload, copycraft_image_upload_gen, copycraft_image_upload_select, copycraft_before_prompt, copycraft_prompt, copycraft_prompt_gen, copycraft_prompt_select, 
      //                                visualads_option, visualads_new_image, visualads_inpainting_get_img, visualads_inpainting_mask, visualads_inpainting, visualads_change_bg_get_img, visualads_change_bg,
      //                                sketchspark_get_image, sketchspark, sketchspark_end, sketchspark_recall
      //                                scanner, smartscan_options, 
      //                                adslibrary, no_adslib, select_adslib, generate_copy, generate_adslib_image, 
      //                                initial_copy, initial_prompt, image_to_copy_prompt, 
      //                                selected_copy_addon, selected_copy_addon_strategy, selected_copy_addon_style,
      //                                display_campaign, more_ads_suggestion, ads_suggestion_strategy, select_suggested_ads
      // Stage 2 (copy)               : strategy,revise
      // Stage 3 (image)              : upload_image, initial_image, recreate_image, regenerate_image, edit_image_part_mask, edit_image_part_inpainting, edit_image_part_background, selected_image_revise, selected_image_edit
      // [X] Stage 4 (before_initial) : brands_selections, platform, marketing_objective, brands_ads_location, ads_location, smart_scan ads_library
      ////////////////////////////////////
      if (!state.chatStage[chat_id]) {
        state.chatStage[chat_id] = { 
          campaign_type: 'Normal',
          stage: '1',
          subStage: 'initial_prompt', 
          subStageOption: '',
          initial_prompt: '',
          initial_reply: '',
          chat_detail_id: '', //for stage '1|initial_prompt'
          selected_copy: '',
          selected_image_url: '',
          selected_image_desc: '',
          product_keyword: '',
          ads_location: '',
          image_size: '',
          image_style: '',
          source_url: '', //for stage 3
          source_desc: '', //for handleStoreReply() stage 3
          mask_url: '', //for handleMasking() stage 3
          mask_desc: '', //for handleMasking() stage 3
          status: 'pending', //pending, start, ongoing, complete, revise(after complete)
          adsclone_copy_id: '',
          adsclone_image_id: '',
          adsclone_copy: '',
          adsclone_image_url: '',
          adsclone_image_desc: '',
        };
        console.log('setChatStage state.chatStage[chat_id]', state.chatStage[chat_id]);
      }
    },
    editChatStage: (state, action) => {
      const { chat_id, updates } = action.payload;
      
      console.log('editChatStage updates', updates);
      console.log('editChatStage before state.chatStage[chat_id]', state.chatStage[chat_id]);

      if (state.chatStage[chat_id]) {
        state.chatStage[chat_id] = {
          ...state.chatStage[chat_id],
          ...updates
        };
      }

      console.log('editChatStage after state.chatStage[chat_id]', state.chatStage[chat_id]);
    },
  },
});

export const { 
  setChatList, 
  setCurrentChatData,
  editCurrentChatData,
  setCurrentChatDetails,
  appendCurrentChatDetails,
  editCurrentChatDetails,
  setChatLoader,
  setLoadingMoreAds,
  setTempPrompt,
  prependCurrentChatDetails,
  setCurrentPage,
  setHasMore,
  setIsLoadingChatDetails,
  setIsLoadingFirstPage,
  setLastChatDetailId,
  setIsSufficientCredit,
  setChatSelection,
  editChatSelection,
  appendChatMemory,
  setBottomRefTrigger,
  setBrandBoostDetail,
  editBrandBoostDetail,
  setChatStage,
  editChatStage,
  setChatOption,
} = chatSlice.actions;

export default chatSlice.reducer;
