//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : dashboard.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 July 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240701     Glynnis      Function features short description
//* 20240718     Jecyline     language selection module
//* 20240726     Jecyline     add create new chat function
//* 20240801     Glynnis      hide api/dash.json
//* 20240805     Jecyline     add feedback form
//* 20240806     Jecyline     amend chatSlice directory
//* 20240807     Jecyline     set feedbackModalShown to true before call api
//* 20240808     Jecyline     Add sweet alert to feedback submission
//* 20240814     Jecyline     Amend dashboard content
//* 20240830     Jecyline     set chat memory and chat details for selected chat room
//* 20240905     Jecyline     move create chat function to chatSagas
//* 20240903     Glynnis      revamp UI
//* 20241003     Jecyline     add Campaign Created to card box
//
//***************************************************************************/
import React, { useEffect, useState } from "react";
import ChatsOverview from "./chats-overview";
import { Link } from 'react-router-dom';

import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useTranslation } from 'react-i18next';
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { api } from '../../../../services/api';
import { useDispatch } from 'react-redux';
import NewChatModal from '../../../../components/modelpopup/NewChatModal'; // Import the new modal component
import { useNavigate } from "react-router-dom";
import FeedbackModal from '../../../../components/modelpopup/FeedbackModal';
import NewAdsModal from '../../../../components/modelpopup/NewAdsModal';

import Swal from 'sweetalert2';
import { GrDiamond } from "react-icons/gr";
import { FaArrowRightToBracket, FaUser } from "react-icons/fa6";
import { 
  createChatAction
} from '../../../../store/actions/chatActions';
import { 
  AdsCloneBtn,
  BrandBoostBtn,
  CopyCraftBtn,
  VisualAdsBtn,
 } from "../../../../Routes/ImagePath";

const AdminDashboard = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showNewChatModal, setShowNewChatModal] = useState(false);
  const [showNewAdsModal, setShowNewAdsModal] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [campaignType, setCampaignType] = useState('');

  useEffect(() => {
    document.body.classList.remove("slide-nav");
    const feedbackModalShown = localStorage.getItem('feedbackModalShown');
    if (feedbackModalShown === 'false') {
      localStorage.setItem('feedbackModalShown', 'true');
      checkChat();
    }

    const fetchData = async () => {
      try {
        const response = await api.get('/dashboard');
        console.log('response',response);
        if (response.status === 200) {
          if(response.data.chart_data){
            const translatedChartData = response.data.chart_data.map(item => ({
              title: t(item.title),
              Total: item.Total
            }));
  
            setChartData(translatedChartData);
          }
          
          setCardData(response.data.card_data || {});
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  },[t]);

  const checkChat = async () => {
    try {
      const response = await api.get('/get-chat');

      if (response.status === 200) {
        const { chats } = response.data;

        if (chats && chats.length > 0) {
          setShowFeedbackModal(true);
        }
      } else {
        console.error('Failed to get data. Response status:', response.status);
      }
    } catch (error) {
      console.error('Failed to get data:', error);
    }
  };

  const handleCloseFeedbackModal = () => {
    setShowFeedbackModal(false);
  };

  const handleCreateFeedback = async (feedback) => {
    try {
      const { ratings, didHelp, improvementSuggestion } = feedback;

      const formData = new FormData();
      formData.append('ratings', ratings);
      formData.append('did_help', didHelp);
      formData.append('improvement_suggestion', improvementSuggestion);

      // Add new feedback
      const response = await api.post('/feedback', formData);

      if (response.status === 200) {
        handleCloseFeedbackModal();
        Swal.fire({
          icon: 'success',
          title: t('Thank You!'),
          text: t('We appreciate your feedback. It helps us improve our service.'),
          confirmButtonText: t('OK'),
        });
    
      } else {
        console.error('Failed to submit feedback. Response status:', response.status);
        Swal.fire({
          icon: 'error',
          title: t('Oops!'),
          text: t("Something went wrong. We couldn't submit your feedback. Please try again later."),
          confirmButtonText: t('OK'),
        });
      }

    } catch (error) {
      console.error('Failed to submit feedback:', error);
    }
  };

  const handleOpenNewChatModal = () => {
    setCampaignType('Normal');
    setShowNewChatModal(true);
  };

  const handleCloseNewChatModal = () => {
    setShowNewChatModal(false);
  };

  const handleCreateChat = async (chatName) => {
    /////////////////////////////////////
    // chatAction: createChatAction
    dispatch(createChatAction({ 
      chat_name: chatName,
      campaign_type: campaignType,
      brand_from: 'Same',
    }));

    // Close the modal after successful chat creation
    handleCloseNewChatModal();
    navigate('/chat');

  };

  const handleOpenNewAdsModal = (campaign_type) => {
    console.log('handleOpenNewAdsModal: campaign_type', campaign_type);
    setCampaignType(campaign_type);
    if(campaign_type === 'BrandBoost' || campaign_type === 'VisualAds' || campaign_type === 'SketchSpark' || campaign_type === 'CopyCraft'){
      setShowNewChatModal(true);
    } else { //AdsClone
      setShowNewAdsModal(true);
    }
  };

  const handleCloseNewAdsModal = () => {
    setShowNewAdsModal(false);
  };

  const handleCreateAdsCampaign = async (chatName, brand) => {
    console.log('handleCreateAdsCampaign: campaignType', campaignType);

    if(brand === 'Other'){
      
      // Close the modal after successful chat creation
      handleCloseNewAdsModal();

      navigate('/chat-brand-scrap', { state: { chat_name: chatName, campaign_type: campaignType, brand_from: 'Other' } });

    } else {
      /////////////////////////////////////
      // chatAction: createChatAction
      dispatch(createChatAction({ 
        chat_name: chatName,
        campaign_type: campaignType,
        brand_from: 'Same'
      }));
      
      // Close the modal after successful chat creation
      handleCloseNewAdsModal();

      navigate('/chat');
    }

  };

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          {/*<Breadcrumbs 
            maintitle={`${t('welcome')} ${cardData.name}!`} 
            title={t('Dashboard')} 
            handleOpenNewChatModal={handleOpenNewChatModal}
          />
          <div className="chat-button-container">
            <div className="chat-button-wrapper">
              <Link
                to="#"
                className="btn add-adsclone-btn"
                onClick={() => handleOpenNewAdsModal('AdsClone')}
              >
                <img src={AdsCloneBtn} alt="AdsClone Button" />
              </Link>
            </div>
            <div className="chat-button-wrapper">
              <Link
                to="#"
                className="btn add-brandboost-btn"
                onClick={() => handleOpenNewAdsModal('BrandBoost')}
              >
                <img src={BrandBoostBtn} alt="BrandBoost Button" />
              </Link>
            </div>
            <div className="chat-button-wrapper">
              <Link
                to="#"
                className="btn add-brandboost-btn"
                onClick={() => handleOpenNewAdsModal('CopyCraft')}
              >
                <img src={CopyCraftBtn} alt="CopyCraft Button" />
              </Link>
            </div>
            <div className="chat-button-wrapper">
              <Link
                to="#"
                className="btn add-brandboost-btn"
                onClick={() => handleOpenNewAdsModal('VisualAds')}
              >
                <img src={VisualAdsBtn} alt="VisualAds Button" />
              </Link>
            </div>
          </div>*/}
          <div className="row">
            <div className="col-12 col-md-12 features_wrapper">
                <div className="row">
                  <div className="md-12">
                   <h1 className="features_sub" >{t('Create Your Winning Ads Start From Here')}</h1>
                  </div>
                </div>
            </div>
          </div>
          <div className="row brand-box">
            {/* AdsClone */}
            <div className="col-6 col-md-4 d-flex">
                <div className="card profile-box flex-fill adsclone-card-wrapper" onClick={() => handleOpenNewAdsModal('AdsClone')}>
                    <div class="adsclone-overlay">
                      <div class="adsclone-text">{t('Clone Your Winning Ads')}</div>
                    </div>
                    <div className="adsclone-title-div">
                      <p className="adsclone-title">{t('Ads Clone')}</p>
                    </div>
                </div>
            </div>
            {/* AdsClone end */}

            {/* Brand Booster */}
            <div className="col-6 col-md-4 d-flex">
              <div className="card profile-box flex-fill brandboost-card-wrapper" onClick={() => handleOpenNewAdsModal('BrandBoost')}>
                <div class="brandboost-overlay">
                  <div class="brandboost-text">{t('Get Ads Insight')}</div>
                </div>
                  <div className="brandboost-title-div">
                    <p className="brandboost-title">{t('Brand Booster')}</p>
                  </div>
              </div>
            </div>
            {/* Brand Booster end */}

            {/* Copycraft */}
            <div className="col-6 col-md-4 d-flex">
              <div className="card profile-box flex-fill copycraft-card-wrapper" onClick={() => handleOpenNewAdsModal('CopyCraft')}>
                <div class="copycraft-overlay">
                  <div class="copycraft-text">{t('Create Creative Copywriting')}</div>
                </div>
                  <div className="copycraft-title-div">
                    <p className="copycraft-title">{t('CopyCraft Wizard')}</p>
                  </div>
              </div>
            </div>
            {/* Copycraft end */}
            {/* VisualAds */}
            <div className="col-6 col-md-4 d-flex">
              <div className="card profile-box flex-fill visualads-card-wrapper" onClick={() => handleOpenNewAdsModal('VisualAds')}>
                <div class="visualads-overlay">
                  <div class="visualads-text">{t('Generate Stunning Images')}</div>
                </div>
                  <div className="visualads-title-div">
                    <p className="visualads-title">{t('Visual Ads Genie')}</p>
                  </div>
              </div>
            </div>
            {/* VisualAds end */}

            {/* SketchSpark */}
            <div className="col-6 col-md-4 d-flex">
              <div className="card profile-box flex-fill sketchspark-card-wrapper" onClick={() => handleOpenNewAdsModal('SketchSpark')}>
                <div class="sketchspark-overlay">
                  <div class="sketchspark-text">{t('Sketch Your Stunning Ads')}</div>
                </div>
                  <div className="sketchspark-title-div">
                    <p className="sketchspark-title">{t('Sketch Spark')}</p>
                  </div>
              </div>
            </div>
            {/* SketchSpark end */}

            {/* VisualLex */}
            <div className="col-6 col-md-4 d-flex">
              <Link className="card profile-box flex-fill visuallex-card-wrapper" to="/visuallex">
                <div class="visuallex-overlay">
                  <div class="visuallex-text">{t('Craft Your Winning Image')}</div>
                </div>
                <div className="visuallex-title-div">
                  <p className="visuallex-title">{t('VisualLex')}</p>
                </div>
              </Link>
            </div>
            {/* VisualLex end */}
          </div>
          <hr className="hr" />
          <div className="row">
            <p className="usage_title" > {t('Usage Summary')} </p>
            {/* Last Login */}
            <div className="col-6 col-md-3 d-flex">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body dashboard-card">
                  <div className="row dashboard-wrapper">
                    {/* <div className="col-12 col-sm-6 dashboard-icon-container">
                      <FaArrowRightToBracket className="icon-size" />
                    </div> */}
                    <div className="col-12 col-sm-12 dashboard-text-container">
                      <div className="dashboard-text dashboard-title">
                        {t('Last Login')}
                      </div>
                      <div className="dashboard-text dashboard-description-time">
                        {/* 4 July 2024 10:00AM */}
                        {cardData.last_login}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Last Login end */}

            {/* Balance Credit */}
            <div className="col-6 col-md-3 d-flex">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body dashboard-card">
                  <div className="row dashboard-wrapper">
                    {/* <div className="col-12 col-sm-6 dashboard-icon-container">
                      <GrDiamond className="icon-size" />
                    </div> */}
                    <div className="col-12 col-sm-12 dashboard-text-container">
                      <div className="dashboard-text dashboard-title">
                        {t('Balance Credit')}
                      </div>
                      <div className="dashboard-text dashboard-description-number">
                        {/* 3 */}
                        {cardData.balance_credit}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Balance Credit end */}

            {/* Successful Affiliate */}
            <div className="col-6 col-md-3 d-flex">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body dashboard-card">
                  <div className="row dashboard-wrapper">
                    {/* <div className="col-12 col-sm-6 dashboard-icon-container">
                      <FaUser className="icon-size" />
                    </div> */}
                    <div className="col-12 col-sm-12 dashboard-text-container">
                      <div className="dashboard-text dashboard-title">
                        {t('Successful Affiliate')}
                      </div>
                      <div className="dashboard-text dashboard-description-number">
                        {/* 5 */}
                        {cardData.successful_affiliate}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Successful Affiliate end */}

            {/* Campaign Created */}
            <div className="col-6 col-md-3 d-flex">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body dashboard-card">
                  <div className="row dashboard-wrapper">
                    {/* <div className="col-12 col-sm-6 dashboard-icon-container">
                      <FaUser className="icon-size" />
                    </div> */}
                    <div className="col-12 col-sm-12 dashboard-text-container">
                      <div className="dashboard-text dashboard-title">
                        {t('Campaign Created')}
                      </div>
                      <div className="dashboard-text dashboard-description-number">
                        {/* 5 */}
                        {cardData.campaign_created}
                      </div>
                      <div className="dashboard-text dashboard-description-link">
                        <Link to="/campaign-summary">{t('View Campaigns')}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Campaign Created end */}
          </div>
          <div className="row">
            {/* Chats Overview */}
            <div className="col-12">
              <div className="card profile-box flex-fill dashboard-card-wrapper">
                <div className="card-body">
                  <h3 className="card-title">
                    {t('Chats Overview')}
                  </h3> 
                  <div className="chat-overview-container">
                    <ChatsOverview 
                      chartData={chartData}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Chats Overview end */}
          </div>
          
        </div>
      </div>
      <NewChatModal 
        showNewChatModal={showNewChatModal} 
        handleCloseNewChatModal={handleCloseNewChatModal} 
        handleCreateChat={handleCreateChat} 
        campaignType={campaignType}
      />
      <NewAdsModal 
        showNewAdsModal={showNewAdsModal} 
        handleCloseNewAdsModal={handleCloseNewAdsModal} 
        handleCreateAdsCampaign={handleCreateAdsCampaign} 
        campaignType={campaignType}
      />
      <FeedbackModal 
        show={showFeedbackModal} 
        onClose={handleCloseFeedbackModal} 
        onCreate={handleCreateFeedback} 
      />
    </div>
  );
};

export default AdminDashboard;
