//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : index.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (01 Oct 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20241001     Jecyline     Add index.s file for Campaign Summary
//
//***************************************************************************/
import React, { Suspense, useState, useEffect } from 'react';

import { Link } from "react-router-dom";
import useAxiosInterceptors from '../../../../hooks/useAxiosInterceptors'; // Import the custom hook
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { 
  getCampaignListAction, 
} from '../../../../store/actions/campaignSummaryActions';

const PolotnoDisplaySidebar = React.lazy(() => import('./polotnoSidebar'));
const PolotnoDisplayView = React.lazy(() => import('./polotnoDisplayView'));

const PolotnoDisplay = () => {
  useAxiosInterceptors(); 
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    // Call the API with the search query
    const fetchResults = async () => {
      /////////////////////////////////////
      // campaignSummaryAction: getCampaignListAction
      dispatch(getCampaignListAction({ 
        name: searchName,
      }));
    };

    fetchResults();
  }, [searchName, dispatch]);

  const handleInputChange = (event) => {
    setSearchName(event.target.value);
  };

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <Suspense fallback={<div>{t('Loading...')}</div>}>
                <PolotnoDisplayView />
              </Suspense>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
};




export default PolotnoDisplay;
