//***************************************************************************
//* Omninexus Sdn Bhd 2024.
//* All Rights Reserved.
//****************************************************************************
//* Filename  : chatApi.jsx
//* Version   : 0.1
//* Author(s) : omninexus
//* Date      : Date (29 Aug 2024)
//****************************************************************************
//*
//* This SOFTWARE is developed by omninexus for ownership and use by omninexus.
//*
//****************************************************************************
//* Change Log
//* Date         Sign         Description
//* ------------ ----------   ------------------------------------------------
//* 20240829     Jecyline     Create chatApi.jsx
//* 20240830     Jecyline     add fetchChatMemory
//* 20240905     Jecyline     add createChatApi(), getChatListApi(), getChatDetailApi(), updateReactionApi(), updateImageSatisfactionApi(), getNextApi(), aiImageRecognitionApi(), aiProofreaderApi(), aiTranslatorApi(), aiBackgroundRegenerateApi(), aiBackgroundRemoveApi(), aiImageMaskApi(), aiImageInpaintingApi()
//* 20240905     Jecyline     add uploadImageApi(), updateChatDetailApi() and amend aiImageFluxApi(), storeReplyApi(), storePromptApi(), aiImageRecognitionApi()
//* 20240911     Jecyline     add updateChatContentFlagApi(), amend aiImageRecognitionApi(): change prompt, amend aiImageFluxApi(): get image size if any
//* 20240923     Glynnis      add react ads library and smart scan API
//* 20240930     Jecyline     add getChatByIdApi, resetSmartScanApi, storeBulkReplyApi 
//* 20240930     Jecyline     amend adsLibraryApi, aiBackgroundRegenerateApi
//* 20241002     Jecyline     amend adsLibraryApi - add cursor parameter
//* 20241004     Jecyline     amend aiImageFluxApi - tune parameters
//
//***************************************************************************/
import axios from 'axios';
import { api } from '../services/api'; //apiClient
import API_ENDPOINTS from './endpoints';
import moment from 'moment';

// Omninexus Backend API ---------------------------------------------- //
export const createChatApi = (chat_name, campaign_type = 'Normal', brand_from = 'Same', introduction_text = '') => {
  //set reply date and time
  const now = moment(); // Get current time
  const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
  const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

  const formData = new FormData();
  formData.append('chat_name', chat_name);
  formData.append('campaign_type', campaign_type);
  formData.append('brand_from', brand_from);
  formData.append('introduction_text', introduction_text);
  formData.append('reply_date', replyDate);
  formData.append('reply_time', replyTime);

  return api.post(API_ENDPOINTS.CHAT(), formData);
};

export const getChatListApi = () => {
  return api.get(API_ENDPOINTS.CHAT());
};

export const getChatByIdApi = (chat_id) => {

  return api.get(API_ENDPOINTS.CHAT_GET_BY_ID(chat_id));
};

export const updateProductKeywordApi = (chat_id, product_keyword) => {
  const parameter = {
    product_keyword: product_keyword,
  };
  return api.post(API_ENDPOINTS.CHAT_UPDATE_PRODUCT_KEYWORD(chat_id), parameter);
};

export const updateAdsLocationApi = (chat_id, ads_location) => {
  const parameter = {
    ads_location: ads_location,
  };
  return api.post(API_ENDPOINTS.CHAT_UPDATE_ADS_LOCATION(chat_id), parameter);
};

export const getChatDetailApi = (chatId, page) => {
  return api.get(API_ENDPOINTS.CHAT_GET_DETAILS(chatId, page));
};

export const fetchChatMemoryApi = (chatId) => {
  return api.get(API_ENDPOINTS.CHAT_FETCH_CHAT_MEMORY(chatId));
};

export const updateReactionApi = (chatDetailId, reply_reaction) => {
  const formData = new FormData();
  formData.append('reply_reaction', reply_reaction);

  return api.post(API_ENDPOINTS.CHAT_UPDATE_REACTION(chatDetailId), formData);
};

export const updateImageSatisfactionApi = (chatDetailId, image_satisfaction) => {
  const formData = new FormData();
  formData.append('image_satisfaction', image_satisfaction);

  return api.post(API_ENDPOINTS.CHAT_UPDATE_IMAGE_SATISFACTION(chatDetailId), formData);
};

export const uploadSmartScanKeyword = (chat_id, promptData) => {
  const formData = new FormData();
  formData.append('product_keyword', promptData.prompt ?? '');

  return api.post(API_ENDPOINTS.CHAT_UPLOAD_SMARTSCAN_KEYWORD(chat_id), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const insertSmartScanApi = (chat_id) => {

  return api.post(API_ENDPOINTS.CHAT_UPLOAD_SMARTSCAN_KEYWORD(chat_id));
};

export const resetSmartScanApi = (chat_id) => {

  return api.post(API_ENDPOINTS.CHAT_RESET_SMART_SCAN(chat_id));
};

export const getNextApi = (prompt) => {
  const formData = new FormData();
  formData.append('prompt', prompt);

  return api.post(API_ENDPOINTS.CHAT_GET_NEXT_API(), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const uploadImageApi = (chatId, promptData) => {
  const formData = new FormData();
  formData.append('prompt', promptData.prompt ?? '');
  formData.append('file_prompt_url', promptData.file_prompt_url);
  formData.append('prompt_date', promptData.promptDate);
  formData.append('prompt_time', promptData.promptTime);
  formData.append('edited_prompt', promptData.edited_prompt ?? '');
  formData.append('image_description', promptData.image_description ?? '');

  return api.post(API_ENDPOINTS.CHAT_UPLOAD_IMAGE(chatId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateChatDetailApi = (chatId, updateData) => {

  return api.post(API_ENDPOINTS.CHAT_UPDATE_DETAIL(chatId), updateData);
};

export const updateChatContentFlagApi = (chatId, updateFlag) => {
  return api.post(API_ENDPOINTS.CHAT_UPDATE_CONTENT_FLAG(chatId), {updateFlag: updateFlag});
};

export const storePromptApi = (chatId, promptData) => {
  const formData = new FormData();
  formData.append('stage', promptData.stage ?? '');
  formData.append('prompt', promptData.prompt ?? '');
  formData.append('prompt_url', promptData.prompt_url ?? '');
  formData.append('file_prompt_url', promptData.file_prompt_url ?? '');
  formData.append('prompt_date', promptData.promptDate);
  formData.append('prompt_time', promptData.promptTime);

  formData.append('prompt_score', promptData.promptScore ?? '');
  formData.append('prompt_api_called', promptData.promptApiCalled ?? '');

  formData.append('edited_prompt', promptData.editedPrompt ?? '');
  formData.append('translated_prompt', promptData.translatedPrompt ?? '');
  formData.append('translation_language', promptData.translationLanguage ?? '');
  formData.append('mask_url', promptData.maskURL ?? '');

  return api.post(API_ENDPOINTS.CHAT_STORE_PROMPT(chatId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const storeReplyApi = (chatId, replyData) => {
  const formData = new FormData();
  formData.append('chat_detail_id', replyData.chat_detail_id ?? '');
  formData.append('reply', replyData.reply ?? '');
  formData.append('reply_url', replyData.reply_url ?? '');
  formData.append('edited_reply', replyData.edited_reply ?? '');
  formData.append('reply_role', replyData.reply_role ?? '');
  formData.append('ui_type', replyData.ui_type ?? '');
  formData.append('mask_url', replyData.mask_url ?? '');

  //set reply date and time
  const now = moment(); // Get current time
  const replyDate = now.format('DD/MM/YYYY'); // Exact date format: DD/MM/YYYY
  const replyTime = now.format('HH:mm:ss'); // Exact time format: HH:mm:ss (24-hour format)

  formData.append('reply_date', replyDate);
  formData.append('reply_time', replyTime);

  return api.post(API_ENDPOINTS.CHAT_STORE_REPLY(chatId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const storeBulkReplyApi = (chatId, replyData) => {
  
  return api.post(API_ENDPOINTS.CHAT_STORE_BULK_REPLY(chatId), {replyData: replyData});
};
// Omninexus Backend API end------------------------------------------- //
// Omninexus Marvis API ----------------------------------------------- //
export const aiImageRecognitionApi = (image_url, prompt = '') => {
  console.log('chatApi: aiImageRecognitionApi image_url', image_url);
  const parameter = {
    url: image_url,
    prompt: prompt || 'What do you see? ignore all the wording in it only tell me the object you see in which position in the image ( top, bottom , left , right , center, top center, top left, top right, center left , center right, center center, bottom center, bottom left or bottom right ) , objection positioning , facing direciton , angel ( facing degrees with dimension ) , color , and the design or shape of the object have to be very clear ,also what is the background and how the background looks like, explain it in a full sentence without giving title , listing and point form , and no new line for it',
  };
  return axios.post(API_ENDPOINTS.MARVIS_RECOGNITION(), parameter);
};

export const aiChatbotApi = (chatMemory) => {
  // return axios.post(`${process.env.REACT_APP_MARVIS_AI}${process.env.REACT_APP_MARVIS_CHATBOT}`,
  return axios.post(API_ENDPOINTS.MARVIS_AI_CHATBOT(), {
    messages: chatMemory,
  });
};

export const aiProofreaderApi = (text) => {
  const parameter = {
    text: text,
  };
  return axios.post(API_ENDPOINTS.MARVIS_PROOFREADER(), parameter);
};

export const aiTranslatorApi = (text) => {
  const parameter = {
    text: text,
    from_lang: "auto",
    to_lang: "english",
    ai_mode: true
  };
  return axios.post(API_ENDPOINTS.MARVIS_TRANSLATOR(), parameter);
};

export const aiImageFluxApi = (prompt, image_url, params) => {
  const parameter = {
    prompt: prompt,
    image_size: params.size === '' ? "landscape_16_9" : params.size,
    num_inference_steps: 50,
    guidance_scale: 8, //3.5
    prompt_magic: true,
    num_images: 1,
    enable_safety_checker: true,
    sync_mode: false,
    photo_real: false,
    seed: null
  };
  if(image_url !== ''){
    parameter.image_url = image_url;
  }
  console.log('chatApi: aiImageFluxApi parameter:', parameter)
  return axios.post(API_ENDPOINTS.MARVIS_IMAGE_FLUX(), parameter);
};

export const aiBackgroundRegenerateApi = (url, text) => {
  const parameter = {
    url: url,
    text: text,
    bg_remove: true,
  };
  return axios.post(API_ENDPOINTS.MARVIS_BACKGROUND_REGENERATE(), parameter);
};

export const aiBackgroundRemoveApi = (url) => {
  return axios.post(API_ENDPOINTS.MARVIS_BACKGROUND_REMOVE(), url);
};

export const aiImageMaskApi = (url, object) => {
  const parameter = {
    url: url,
    object: object,
  };
  console.log('chatApi: aiImageMaskApi parameter', parameter);
  return axios.post(API_ENDPOINTS.MARVIS_IMAGE_MASK(), parameter);
};

export const aiImageInpaintingApi = (prompt, url, mask_url) => {
  const parameter = {
    prompt: prompt,
    image: url,
    mask: mask_url,
    strength: 1.0,
    num_inference_steps: 30,
    guidance_scale: 8,
    height: 1024,
    width: 1024,
    turbo: false,
    sync_mode: false,
    seed: null,
    enable_safety_checker: true,
    num_outputs: 4,
    output_format: "jpg",
    output_quality: 100
  };
  return axios.post(API_ENDPOINTS.MARVIS_IMAGE_INPAINTING(), parameter);
};

export const storeProductKeywordApi = (chatId, promptData) => {
  const formData = new FormData();
  formData.append('product_keyword', promptData.prompt ?? '');

  return api.post(API_ENDPOINTS.CHAT_UPLOAD_SMARTSCAN_KEYWORD(chatId), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const smartScanApi = (params) => {
  const parameter = {
    __ad_account_id: 357014735817109,
    _reqName: {"objectByName":"reachfrequencyestimates"},
    _reqSrc: "ReachFrequencyEstimatesClient",
    account_id: 357014735817109,
    frequency_cap: 2,
    auction_entry_option_index: 0,
    budget: params.budget,
    buying_type: "RESERVED",
    currency: "MYR",
    day_parting_schedule: [{"start_minute":0,"end_minute":1440,"days":[0,1,2,3,4,5,6]}],
    destination_ids: ["144984656064845","1355843967817404"],
    ad_formats: [{"details":{"canvas":false,"categories":["other"]},"type":"image"}],
    grp_buying: false,
    instream_packages: [],
    interpolated_reach: 200000,
    interval_frequency_cap_reset_period: 168,
    is_balanced_frequency: false,
    is_higher_average_frequency: false,
    is_reach_and_frequency_io_buying: false,
    is_reserved_buying: false,
    locale: "en_GB",
    method: "GET",
    objective: "OUTCOME_AWARENESS",
    optimization_goal: "AD_RECALL_LIFT",
    prediction_mode: "REACH",
    reach: 200000,
    source: "AdsReachFrequencyFlow.CHANGE_INSTAGRAM_DESTINATION",
    start_time: 1736943400,
    stop_time: 1742213740,
    story_event_type: 0,
    target_frequency: 1,
    target_frequency_reset_period: 168,
    timezone: 71,
    target_spec: {"age_max":65,"age_min":18,"genders":[],"geo_locations":{"cities":[],"countries":["AE"],"regions":[],"zips":[],"location_types":["home","recent"]},"user_os":[],"user_device":[],"excluded_user_device":[],"wireless_carrier":[],"excluded_publisher_list_ids":[],"brand_safety_content_filter_levels":["FACEBOOK_STANDARD"],"excluded_brand_safety_content_types":[],"publisher_platforms":["facebook","instagram"],"facebook_positions":["feed","instream_video","video_feeds","marketplace","story","facebook_reels_overlay","search","facebook_reels"],"instagram_positions":["stream","story","explore","explore_home","reels"],"audience_network_positions":[],"messenger_positions":[],"oculus_positions":[],"device_platforms":["mobile","desktop"]},
    cookie: 'datr= huVDZoDaJ3Gcjch9Udi6l392;sb=huVDZn7n2dxlLMw9dpLeeJma;ps_n=1;ps_l=1;c_user=100068827194171;locale=en_US;ar_debug=1;cppo=1;fr=1kJPgNWUuiKqsTP8v.AWW6XAaNjuRFEh4jwkZVzw6Ci5A.Bm7faG..AAA.0.0.Bm7faG.AWXIj0jV5wo;xs=26%3AnRZ042sau-7ZTw%3A2%3A1726849013%3A-1%3A12335%3A%3AAcULtF208nCsyjT9i34gye5V5KBu44LN9JTx24HX2Q;wd=1512x823;usida=eyJ2ZXIiOjEsImlkIjoiQXNrNHYwOGloYXpvZiIsInRpbWUiOjE3MjY4NzIwNTZ9;presence=C%7B%22t3%22%3A%5B%5D%2C%22utc3%22%3A1726872068177%2C%22v%22%3A1%7D;|Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36|Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/129.0.0.0 Safari/537.36',
    token: 'EAABsbCS1iHgBO8BpJ0t98dizkdDEWlsZCDgIusDCFD6GVHZCCT9p6mBKZCVKQdVZCV8oXRaTGSM1ZB9QETEfu2WcEp0t5HcDkCz5HeTkZCXwYNY3FOjB2sCwbxKObCRX9KaMSuyWVENYL4TTsgDEtL7kr9w68fi5BT0D4EOd0OuIFoHM7CnMRlZAO392OkYFKyW7UcPmLhH1CyMwgZDZD',
  };

  console.log('chatApi: smartScanApi parameter:', parameter)
  return axios.post(API_ENDPOINTS.REACT_APP_MARVIS_SMART_SCAN(), parameter);
};

export const adsLibraryApi = (params) => {
  console.log('chatApi: adsLibraryApi changeable params:', params)

  const parameter = {
    queryString: params.keywords,
    // queryString: params.keywords,
    searchType: 'KEYWORD_UNORDERED',
    viewAllPageID: 0,
    activeStatus: 'ALL',
    adType: 'ALL',
    countries: 'US',
    country: 'US',
    // countries: [params.ads_location],
    // country: params.ads_location,
    fetchPageInfo: true,
    fetchSharedDisclaimers: true,
    mediaType: 'ALL',
    publisherPlatforms: [params.platform],
    audienceTimeframe: 'LAST_30_DAYS',
    sortData: {"mode": "SORT_BY_TOTAL_IMPRESSIONS", "direction": "DESCENDING"},
    bylines: [],
    potentialReachInput: [{"min": 500000, "max": 2000000}],
    regions: [],
    cursor: params.end_cursor ?? "",
  };
  if(params.first && params.first !== ''){
    parameter.first = params.first;
  }
  console.log('chatApi: adsLibraryApi parameter:', parameter)

  return axios.post(API_ENDPOINTS.MARVIS_ADS_LIBRARY(), parameter);
};

export const aiCountriesApi = () => {
  return axios.get(API_ENDPOINTS.MARVIS_COUNTRIES());
};

export const aiAdsInfoFromFBUrlApi = (url) => {
  return api.get(API_ENDPOINTS.MARVIS_ADS_INFO_FROM_FB_URL(url));
};

export const aiAdsInterestScraperApi = (keyword) => {
  console.log('chatApi: aiAdsInterestScraperApi keyword:', keyword)

  return api.get(API_ENDPOINTS.MARVIS_INTEREST_SCRAPER(keyword));
};

export const adsMetricsApi = (params) => {
  console.log('chatApi: adsMetricsApi changeable params:', params)

  const parameter = {
    budget: params.budget,
    day_parting_schedule: JSON.stringify([{"start_minute": 0, "end_minute": 1440, "days": [0, 1, 2, 3, 4, 5, 6]}]),
    destination_id: '474978042357414',
    frequency_cap: 2,
    instream_packages: JSON.stringify([]),
    interval_frequency_cap_reset_period: 168,
    objective: 'OUTCOME_AWARENESS',
    optimization_goal: 'AD_RECALL_LIFT',
    prediction_mode: 0,
    reach: params.reach, //200000,
    start_time: 1733763610,
    stop_time: 1741539540,
    story_event_type: 0,
    target_spec: params.target_spec,
    currency: 'USD',
  }
  console.log('adsMetricsApi parameter:', parameter)

  const encodedParam = new URLSearchParams(parameter).toString();

  console.log('adsMetricsApi encodedParam:', encodedParam)

  return axios.post(API_ENDPOINTS.MARVIS_ADS_METRICS_NEW(), encodedParam);
};
// Omninexus Marvis API end-------------------------------------------- //
